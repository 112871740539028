import en from './en'
import zh from './zh'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import VueI18n from 'vue-i18n'
import Vue from 'vue'
import store from './../store'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: store.state.global.lang,
  messages: {
    en: {
      ...en,
      ...enLocale,
      ...window.lang.en
    },
    zh: {
      ...zh,
      ...zhLocale,
      ...window.lang['zh-cn']
    }
  }
})
export default i18n
