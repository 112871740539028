import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'

Vue.use(VueRouter)
/* 加载进度 */
NProgress.configure({
  speed: 500,
  showSpinner: false
})

const routes = [
  {
    path: '/',
    component: () => import('@/views/index'),
    redirect: 'home',
    children: [{
      path: 'home',
      component: () => import('@/views/home')
    }, {
      path: 'mall',
      component: () => import('@/views/mall'),
      redirect: 'mall/list',
      children: [{
        path: 'list',
        component: () => import('@/views/mall/MallList')
      }, {
        path: 'detail/:id',
        component: () => import('@/views/mall/MallDetail')
      }, {
        path: 'detail/:id?/order',
        component: () => import('@/views/mall/MallDetailOrder')
      }]
    }, {
      path: 'support',
      component: () => import('@/views/support')
    }, {
      path: 'about-us',
      component: () => import('@/views/aboutUs')
    }, {
      path: 'community',
      component: () => import('@/views/community')
    }, {
      path: 'news',
      component: () => import('@/views/news'),
      redirect: 'news/list',
      children: [
        {
          path: 'list',
          component: () => import('@/views/news/NewsList')
        }, {
          path: 'detail/:id',
          component: () => import('@/views/news/NewsDetail')
        }
      ]
    }, {
      path: 'product/list',
      component: () => import('@/views/product')
    }, {
      path: 'product/detail/:id',
      component: () => import('@/views/product/ProductDetail')
    }, {
      path: 'cart',
      component: () => import('@/views/cart')
    }, {
      path: 'search',
      component: () => import('@/views/search')
    }, {
      path: 'letter',
      component: () => import('@/views/letter/index')
    }]
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  NProgress.start()
  next()
})

router.afterEach((to, from) => {
  NProgress.done()
})

export default router
