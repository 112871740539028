export default {
  state: {
    lang: window.localStorage.getItem('lang') || 'zh',
    bgColor: '#fff',
    navList: [],
    cartList: JSON.parse(window.localStorage.getItem('cart') || '[]')
  },
  mutations: {
    setLang: function (state, lang) {
      state.lang = lang
      if (window.localStorage) {
        window.localStorage.setItem('lang', lang)
      }
    },
    setBgColor: function (state, bgColor) {
      state.bgColor = bgColor
    },
    setNavList: function (state, navList) {
      state.navList = navList
    },
    setCartList: function (state, cartList) {
      state.cartList = cartList
      window.localStorage.setItem('cart', JSON.stringify(cartList))
    }
  },
  getters: {
    getIdByName: (state) => (name) => {
      return state.navList.length ? state.navList.find(item => item.diyname === name).id : ''
    }
  }
}
